export function getUserObj(dataOsoba = 
  {
    has_stock_control: true, // true => false
    export_excel: true,
    branch_new: true,
    article_desc: true,
    article_orderby: "",
  }
) {
  return {
    firstname: dataOsoba?.imie || "",
    lastname: dataOsoba?.nazwisko || "",
    company: dataOsoba?.nazwa_ktr || "",
    price_change: !!dataOsoba?.can_negotiate_price,
    stock_unlimit: !(!!dataOsoba?.has_stock_control),
    export_excel: !!dataOsoba?.export_excel,
    price_catalog: !!dataOsoba?.price_catalog,
    rabat_procent: !!dataOsoba?.rabat_procent,
    rabat_feature: !!dataOsoba?.rabat_feature,
    cart_dialog: !!dataOsoba?.cart_dialog,
    crosseling_mode: !!dataOsoba?.crosseling_mode,
    favorite_display: !!dataOsoba?.favorite_display,
    ribbon_display: !!dataOsoba?.ribbon_display,
    branch_price: !!dataOsoba?.branch_price,
    branch_show: !!dataOsoba?.branch_show,
    branch_odb: !!dataOsoba?.branch_odb,
    branch_new: !!dataOsoba?.branch_new,
    tariff_change: !!dataOsoba?.can_add_tariff,
    article_popup: !!dataOsoba?.article_popup,
    article_pdf: !!dataOsoba?.article_pdf,
    article_desc: !!dataOsoba?.article_desc,
    article_orderby: dataOsoba?.article_orderby || "",
    filter_show: !!dataOsoba?.filter_show,
    margin_show: !!dataOsoba?.show_margin,
    price_purchase: !!dataOsoba?.show_price_purchase,
    trans_enable: dataOsoba?.trans_enable || 0,
    extra_info: dataOsoba?.extra_info || "",
    feature_home: dataOsoba?.feature_home || 0,
    credit: { limit: 0, dept: -1, days: 0 },
    currency: dataOsoba?.currency || "PLN",
    is_han: dataOsoba?.is_han || "",
    is_ktr: dataOsoba?.is_ktr || "",
    is_odb: dataOsoba?.is_odb || "",
    modules: [0, 0],
    colors: { primary: "", secondary: "", highlight: ""},
    logo: "",
    l_feat_basic: dataOsoba?.et_cech_p || "Cechy", // Wyposażenie podstawowe
    l_feat_extra: dataOsoba?.et_cech_o || "Właściwości zamówienia", // Wyposażenie dodatkowe,
    type_price: dataOsoba?.type_price || 0,
    profile: "",
  };
}

export function parseJSON(jsonString) {
  try {
    // if (jsonString == undefined) return false;
    const jsonObj = JSON.parse(jsonString);

    if (jsonObj && typeof jsonObj === 'object') {
      return jsonObj;
    }
  }
  catch (e) {
    console.log('JSON error', e);
  }

  return false;
}

export function parseProperty(obj, json = true) {
  if (obj == undefined || typeof obj !== "object" || Object.keys(obj).length === 0) {
    return '';
  }

  const list = [];
  for (const [field, value] of Object.entries(obj)) {
    list.push({ field, value });
  }

  return json ? JSON.stringify(list) : list;
}

export function parsePropertyJSON(obj) {
  if (obj == '' || obj.length == 0) return {};

  const list = parseJSON(obj);
  if (!list) return {};

  return list.reduce((acc, curr) => ({ ...acc, [curr.field]: curr.value }), {});
}

export function shallowEqual(object1, object2, ignore = 0) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length && ignore == 0) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      if (ignore == 1 && object2[key] === undefined) continue;
      if (ignore == 2 && object1[key] === undefined) continue;
      return false;
    }
  }

  return true;
}

export const isEqual = (a, b) => {
  JSON.stringify(a) === JSON.stringify(b);
};

export const isObject = (object) => {
  return object != null && typeof object === "object";
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

String.prototype.numberWithCommas = function() {
  var parts = this.toString().split(",");
  return "" + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (parts[1] ? "," + parts[1] : "");
}

export const numberFormatVat = (value) => {
  if (value == undefined) return 23;
  if (value === 1) return 1; // fix api
  if (value === 0) return 1;

  return (parseInt(value, 10) * 0.01) + 1;
};

export const numberFormatCurrency = (value, currency) => {
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: (/^[A-Z]{3}$/.test(currency) == false) ? 'PLN' : currency,
  }).format(value).numberWithCommas().replaceAll(" ", "\xa0");
};

export const numberFormatQuantity = (value, precision) => {
  const precisionDefault = precision == undefined ? 3 : +precision;
  const options = { };

  if (precisionDefault >= 0) {
    options["minimumFractionDigits"] = precisionDefault;
    options["maximumFractionDigits"] = precisionDefault;
  }

  return new Intl.NumberFormat('pl-PL', options).format(value);
}

export function debounce(callback, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

export function throttle(callback, limit, delay = false) {
  let wait = false;
  return (...args) => {
    if (!wait) {
      if (!delay) {
        callback.apply(this, args);
      }
      delay = false;
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
}

export function fireWait(callback) {
  let wait = false;
  return () => {
    if (wait === false) {
      wait = true;
      callback.call().then(() => {
        wait = false;
      });
    }
  };
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function truncateString(string = '', maxLength = 50) {
  return string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;
}

export function isPopupBlocked(popupWindow, handleStatus) {
  const CHROME_CHECK_TIME = 2000;

  function _is_popup_blocked(popup) {
    return !popup.innerHeight;
  }

  if (!popupWindow) {
    handleStatus(true);
    return;
  }

  if (popupWindow.closed) {
    handleStatus(false);
    return;
  }

  if (/chrome/.test(navigator.userAgent.toLowerCase())) {
    setTimeout(function() {
      handleStatus(_is_popup_blocked(popupWindow));
    }, CHROME_CHECK_TIME);
  } else {
    popupWindow.onload = function() {
      handleStatus(_is_popup_blocked(popupWindow));
    };
  }
};

export function makeId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function hashCode(s) {
  return s.split("").reduce(function(a, b) {
    a = ((a<<5 ) - a) + b.charCodeAt(0);
    return a&a;
  }, 0);
};

export function uid() {
  return (performance.now().toString(36)+Math.random().toString(36)).replace(/\./g,"");
}

export function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export function checkMimeType(file) {
  return new Promise(resolve => {
    const fileReader = new FileReader();

    fileReader.onloadend = function(e) {
      const byteArray = new Uint8Array(e.target.result);

      if (byteArray[0] == 255 && byteArray[1] == 216){
          resolve('image/jpeg');
          return;
      }

      const td = new TextDecoder("utf-8");
      const headerString = td.decode(byteArray);
      const mimeTypes = [
        ['CSV', 'text/csv'],
        ['RTF', 'application/rtf'],
        ['XLS', 'application/vnd.ms-excel'],
        ['XLSX', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        ['DOC', 'application/msword'],
        ['DOCX', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        ['PPT', 'application/vnd.ms-powerpoint'],
        ['PPTX', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
        ['PNG', 'image/png'],
        ['BMP', 'image/bmp'],
        ['%PDF', 'application/pdf'],
        ['TXT', 'text/plain'],
      ];

      for(let i = 0;i < mimeTypes.length;i++){
        if(headerString.indexOf(mimeTypes[i][0]) > -1){
          resolve(mimeTypes[i][1]);
          return;
        }
      }

      resolve('text/html');
    };

    fileReader.readAsArrayBuffer(file.slice(0,32));
  });
}

export function getSearchArticleSQL(search) {
  if (search.length == 0) return "";
  return search;
}

export function compareNumbers(n1, n2) {
  if (+n1 > +n2) return 1;
  if (+n1 < +n2) return -1;

  return 0;
}

export function compareDates(d1, d2 = "") {
  if (d2 == "") {
    const currDate = new Date();
    d2 = `${currDate.getFullYear()}-${currDate.getMonth()+1}-${currDate.getDate()}`;
  }

  const date1 = new Date(d1.slice(0, 10));
  const date2 = new Date(d2.slice(0, 10));

  if (date1 > date2) {
    return 1;
  }

  if (date1 < date2) {
    return -1
  }

  return 0;
}

export function roundTo(n, digits) {
  var negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(digits);
  if (negative) {
    n = (n * -1).toFixed(digits);
  }

  return parseFloat(n); // fix compare numbers
}

export function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export function toValidNumber(num) {
  const str = String(num).replace(/[^0-9.,]+/g, '').replace(/[,]+/g, '.');
  const len = str.length;
  const arr = [];
  let dot = -1;

  for (let i = 0; i < len; i++) {
    if (str[i] === '.' && ++dot > 0) continue;
    if (str[i] !== '.' && Number.isNaN(+str[i])) continue;
    arr.push(str[i]);
  }

  return arr.join('');
}

export function toFixedCut(num, fixed = 0) {
  let str = num.toString();
  let dot = str.indexOf(".");
  if (dot == -1) {
    return Number(num).toFixed(fixed);
  }

  str = str.slice(0, dot + (fixed) + 1);
  return Number(str).toFixed(fixed);
}

export function toBoolean(str) {
  if (typeof str === 'undefined' || str === null) {
    return false;
  }

  if (typeof str === 'string') {
    switch (str.toLowerCase()) {
      case 'false':
      case 'no':
      case '0':
      case '': return false;
      default:
        return true;
    }
  }

  if (typeof str === 'number') {
    return str !== 0;
  }

  return true;
}

export function sanitizeFilename(filename) {
  filename = String(filename || "dokument").normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  filename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();

  return filename;
}

export function getCurrentDate() {
  return (new Date()).toDateInput();
}

export function getDownloadFile(blob, fileName) {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('A');
  link.style = 'display: none';
  link.href = url;
  // link.setAttribute("href", url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  // document.getElementsByTagName("body")[0].append(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
  blob = null;
}

export function convertRgbToHex(rgbString) {
  if (rgbString.length == 0) return "#000000";
  const rgb = rgbString.split(',');

  if (rgb.length != 3) return "#000000";

  return '#' + rgb.map(x => {
    const hex = parseInt(x).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }).join('');
}

export function applyColors(colors) {
  if (Object.prototype.toString.call(colors) != '[object Object]' || Object.keys(colors).length != 3) return;
  if (colors.primary == "#ffffff") return;

  const cssRoot = document.querySelector(':root');
  cssRoot.style.setProperty('--primary', colors.primary);
  cssRoot.style.setProperty('--secondary', colors.secondary);
  cssRoot.style.setProperty('--teriary', colors.highlight);
}

export function isEmpty(val) {
  switch (val) {
    case "":
    case 0:
    case "0":
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
}

String.prototype['compareTimeStampNow'] = function() {
  if (/^(\d){4}-(\d){2}-(\d){2}T(\d){2}:(\d){2}:(\d){2}Z$/.test(this.toString()) == false) return false;

  const d = this.toString().slice(0, 10).split('-');
  const t = this.toString().slice(11, 19).split(':');

  const date = new Date(+d[0], +d[1]-1, +d[2], +t[0], +t[1], +t[2]);
  const ts1 = Math.floor(date / 1000);
  const ts2 = Math.floor(Date.now() / 1000);

  return ts1 - ts2;
}

String.prototype['compareDate'] = function(date1, date2) {
  // console.log("compareDate", this, date1, date2)
  //if ((this.toString().match(/\./g) || []).length >= 2)

  const d0 = this.toString().slice(0, 10).split('-');
  const d1 = date1.toString().slice(0, 10).split('-');
  const d2 = date2.toString().slice(0, 10).split('-');
  const dd0 = new Date(+d0[0], +d0[1]-1, +d0[2]);
  const dd1 = new Date(+d1[0], +d1[1]-1, +d1[2]);
  const dd2 = new Date(+d2[0], +d2[1]-1, +d2[2]);
  return dd1 <= dd0 && dd0 <= dd2;
}

String.prototype['toDateInput'] = function(empty = false) {
  let dateParsed = '';

  if (this.toString().length <= 10) 
    dateParsed = this.toString().trim().replaceAll('.', '-');
  else
    dateParsed = this.toString().trim().slice(0, 10).replaceAll('.', '-');

  if (empty == true && dateParsed == '1900-01-01')
    return '';

  // console.log("%ctoDateInput", 'background:#222;color:#bada55', dateParsed, this.toString());
  return dateParsed;
};

String.prototype['toDateCalendar'] = function() {
  if (this.toString() == "") return new Date();

  let dateParsed = '';

  if (this.toString().length <= 10) 
    dateParsed = this.toString().trim().replaceAll('.', '-');
  else
    dateParsed = this.toString().trim().slice(0, 10).replaceAll('.', '-');
  
  const date = dateParsed.split('-');

  if (date.length != 3)
    return new Date();

  return new Date(date[0], date[1]-1, date[2]);

};

String.prototype['translateCurrency'] = function () {
  switch (this) {
    case "PLN": return "zł";
    case "EUR": return "€";
    case "USD": return "$";
    case "GBP": return "£";
    case "RUB": return "₽";
    case "JPY": return "¥";
    case "TRY": return "₤";
    case "UAH": return "₴";
    case "BTC": return "₿";
  }

  return "zł";
}

String.prototype['normalizePolishChars'] = function() {
  return this.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");
};

String.prototype['includesMulti'] = function(search /* String */) {
  let inc = 0;
  let tru = 0;
  const val = search.normalizePolishChars();
  const valThis = this.toString().toLowerCase().normalizePolishChars();

  const sAnd = val.match(/[^\s]* AND [^\s]*/gsu);
  if (sAnd != null) {
    sAnd.forEach((wAnd) => {
      inc = 0;
      const wAndS = wAnd.split(" AND ");
      wAndS.forEach((zAnd) => {
        if (zAnd.trim().length == 0) {
          inc++
          return ;
        };

        inc = inc + valThis.includes(zAnd.trim().toLowerCase());
      });

      if (inc == wAndS.length)
        tru++;
    });

    if (tru != sAnd.length)
      return false;
  }

  const sOr = val.match(/[^\s^]* OR [^\s]*/gsu);
  if (sOr != null) {
    sOr.forEach((wOr) => {
      inc = 0;
      wOr.split(" OR ").forEach((zOr) => {
        if (zOr.trim().length == 0) {
          inc++
          return;
        };

        inc = inc + valThis.includes(zOr.trim().toLowerCase());
      });

      if (inc > 0)
        tru++;
    });

    if (tru == 0)
      return false;
  }

  const sExc = val.match(/([\s]+|(^\w)+|^[\w]*)NOT [^\s]*/gsu);
  if (sExc != null) {
    inc = 0;
    sExc.forEach((wExc) => {
      if (wExc.trim().length == 0 || wExc.indexOf("NOT ") == -1) return;
      inc = inc + valThis.includes(wExc.trim().slice(4).toLowerCase());
    });

    if (inc > 0)
      return false;

    tru = 1
  }

  if (tru > 0) {
    return true;
  }

  return valThis.includes(val.trim().toLowerCase());
};

Date.prototype['toDateInput'] = function(sliceYear = 0) {
  return `${this.getFullYear().toString().slice(sliceYear)}-${(""+(this.getMonth()+1)).padStart(2, '0')}-${(""+this.getDate()).padStart(2, '0')}`
};

Date.prototype['toDateHermes'] = function(sliceYear = 0) {
  return `${(""+this.getDate()).padStart(2, '0')}.${(""+(this.getMonth()+1)).padStart(2, '0')}.${this.getFullYear().toString().slice(sliceYear)}`
};

Array.prototype.lastElement = function() {
  return this[this.length - 1];
}

Number.prototype['toPolishFormat'] = function(delimiter = "\xa0", fixed = 2) { // String.fromCharCode(160)
  const REGEX_GROUPS = /(\d)(?=(\d\d\d)+(?!\d))/g
  const val = this.toString().replace(REGEX_GROUPS, `$1${delimiter}`).split(".");
  return val.length > 1 ? `${val[0]},${val[1].padEnd(fixed, "0")}` : val[0];
}
